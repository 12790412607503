import React, { useState, useEffect } from "react";
import Navbar from "./Navbar.js";
import Sidebar from "./Sidebar.js";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useHistory } from "react-router";
import { toast, ToastContainer } from 'react-toastify';

export default function Appointment() {
    const [name, setName] = useState('')
    const [token, setToken] = useState('')
    const [expire, setExpire] = useState('')
    const [categories, setCategories] = useState('')
    const [addname, setAddName] = useState('')
    const history = useHistory();
    
    useEffect(() => {
      refreshToken();
    }, []);
    
    const refreshToken = async () => {
      try {
          const response = await axios.get('https://app.thepinkindex.com/token');
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setName(decoded.name);
          setExpire(decoded.expire);
      } catch (error) {
          if (error.response) {
            history.push("/");
          }
      }
    }
  
    const axiosJWT = axios.create();
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://app.thepinkindex.com/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const Auth = async (e) => {
      e.preventDefault()
      try {
        const postData= {subCategory: addname, category: categories,provider: name}
        const header = { headers: { Authorization: `Bearer ${token}` }}
        await axios.post('https://app.thepinkindex.com/register/subcategory',header,postData);
        history.push("/provider/dashboard");
      } catch (error) {
        if (error.response) {
          toast.info('👿👿👿' + error.response.data.msg, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      }
    }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100 mt-24">
        <Navbar />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
            <section className="relative py-20">
                <div className="container mx-auto px-4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded mt-12">
                          <div className="rounded-t mb-0 px-4 py-3 border-0 bg-gray-900">
                            <div className="flex flex-wrap items-center">
                              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3 className="font-semibold text-base text-white uppercase">
                                  Service Setup
                                </h3>
                              </div>    
                            </div>
                          </div>
                          <div className="relative w-full overflow-x-auto ml-8">
                              <div className="flex flex-wrap">
                                  <div className="mt-6 rounded overflow-hidden w-full ">
                                      <form className="relative mt-2 min-w-0 flex flex-col" onSubmit={Auth}>
                                        <div className="flex flex-wrap -mx-3 mb-6 ml-4 w-full">
                                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                        Service Category
                                                    </label>
                                                    <select value={categories} onChange={(e)=>setCategories(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                                        <option value="" selected>Select Category</option>
                                                        <option value="1">Body</option>
                                                        <option value="2">Face</option>
                                                        <option value="3">Hair</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                                <div class="relative flex w-full flex-wrap items-stretch mb-3">
                                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                    Service Name
                                                  </label>
                                                  <input type="text" value={addname} onChange={(e)=>setAddName(e.target.value)} className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full"/>
                                                </div>
                                            </div>
                                        </div>                                         
                                        <div className="flex flex-wrap -mx-3 mb-6 ml-4 w-full">
                                          <div className="w-full md:w-1/1 px-3 mb-6 md:mb-0">
                                            <input
                                                className="bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                type="submit"
                                                value="Submit"
                                                style={{ transition: "all .15s ease" }}
                                            />
                                          </div> 
                                        </div>
                                      </form>
                                  </div> 
                              </div>
                          </div>
                    </div>
                </div>
            </section>
        </div>
      </div>
    </>
  );
}
