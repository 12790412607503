import React, { useState, useEffect } from "react";
import Navbar from "./Navbar.js";
import Sidebar from "./Sidebar.js";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function StatusPostTable() {
    const [name, setName] = useState('')
    const [token, setToken] = useState('')
    const [expire, setExpire] = useState('')
    const [status, setStatus] = useState([])
    const history = useHistory();

    useEffect(() => {
      refreshToken();
      getStatusPost();
    }, []);

    async function getStatusPost () {
      try{
        let url = 'https://app.thepinkindex.com/status/post/get';
        const data = await axiosJWT.get(url, { headers: { Authorization: `Bearer ${token}` }});
        console.log(data);
        setStatus(data.data);
      }catch(err){
        console.log(err)
      }
    }
  
      const refreshToken = async () => {
        try {
            const response = await axios.get('https://app.thepinkindex.com/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.expire);
        } catch (error) {
            if (error.response) {
              history.push("/");
            }
        }
      }
  
      const axiosJWT = axios.create();
      axiosJWT.interceptors.request.use(async (config) => {
          const currentDate = new Date();
          if (expire * 1000 < currentDate.getTime()) {
              const response = await axios.get('https://app.thepinkindex.com/token');
              config.headers.Authorization = `Bearer ${response.data.accessToken}`;
              setToken(response.data.accessToken);
              const decoded = jwt_decode(response.data.accessToken);
              setName(decoded.name);
              setExpire(decoded.expire);
          }
          return config;
      }, (error) => {
          return Promise.reject(error);
      });

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100 mt-24">
        <Navbar />        
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
        <section className="relative py-20">
          <div className="container mx-auto px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded mt-12">
                <div className="rounded-t mb-0 px-4 py-3 border-0 bg-gray-900">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h3 className="font-semibold text-base text-white uppercase">
                        Status Activities 
                      </h3>
                    </div>
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                      <Link
                        className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        to='/provider/upload/status'
                      >
                        Post New Status
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Image
                      </th>
                      <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Caption
                      </th>
                      <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      status.map((st) => (
                        <tr>
                          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                            <img src={ st.url } width="80" height="60" />
                          </th>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            { st.caption }
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <a className='icon-text is-small' href='/users'>
                              <span class="icon has-text-warning"><i class="fas fa-exclamation-triangle"></i></span>
                            </a> &nbsp; &nbsp;
                            <a className='icon-text is-small' href='/users'>
                              <span class="icon has-text-danger"><i class="fas fa-ban"></i></span>
                            </a>                            
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        </div>
      </div>
    </>
  );
}
