import React from "react";
import Picture1 from "assets/img/ziphaus-gexSJRjb0qw-unsplash.jpg";

export default function Home() {
  
  return (
    <>
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center" style={{ height: "960px" }}>
          
            <div className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${Picture1})`
              }}>
                <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
            </div>
        
            <div className="container relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                        <div className="pr-12">
                            <h1 className="text-white font-semibold text-5xl uppercase">
                            Coming Soon
                            </h1>
                            <br/><br/>
                            <h6 className="text-white text-xl">follow us for more updates!</h6>
                        </div>
                        <div className="pr-12">
                            <form class="w-full max-w-sm pt-12">
                                <div class="flex border-b border-teal-500 py-2">
                                    <input class="appearance-none bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none" type="email" placeholder="Your Email Address" aria-label="Full name" />
                                    
                                    <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" type="button">
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </main>
    </>
  );
}
