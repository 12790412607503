import React, {useState} from "react";
import {FaStar} from "react-icons/fa";

export default function StarRating() {
    const [rating, setRating] = useState(null)
    const [hover, setHover] = useState(null)
  return (
    <>
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
                <div className="flex items-center p-2 md:p-4 text-sm">
                    {[...Array(5)].map((star, index) => {
                        const currentRating = index + 1;
                        return(
                            <label>
                                <input 
                                    type="radio" 
                                    name="rating"
                                    className="rating" 
                                    value={currentRating} 
                                    onClick={() => setRating(currentRating)} 
                                />
                                <FaStar 
                                    size={50} 
                                    className='star' 
                                    onMouseEnter={() => setHover(currentRating)} 
                                    onMouseLeave={()=>setHover((null))} 
                                    color={currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"} 
                                />
                            </label>
                        );
                    })}
                    <p>Your Rating Is {rating} </p>
                </div>
            </div>
        </div>
    </>
  );
}
