import React, { useState, useEffect } from "react";
import Navbar from "./Navbar.js";
import Sidebar from "./Sidebar.js";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useHistory } from "react-router";
import { toast, ToastContainer } from 'react-toastify';
import storage from "../../config/firebaseConfig.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export default function Setup() {
    const [name, setName] = useState('')
    const [token, setToken] = useState('')
    const [expire, setExpire] = useState('')
    const [categories, setCategories] = useState('')
    const [cate, setCate] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [feeCondition, setFeeCondition] = useState('');
    const [feeChargeable, setFeeChargeable] = useState('')
    const [tagcheck, setTagCheck] = useState("Mobile")
    const [file, setFile] = useState('')
    const [urls, setUrls] = useState('')
    const [percent, setPercent] = useState(0);
    const history = useHistory();

    function handleChange(event) {
      setFile(event.target.files[0]);
    }

    const onOptionChange = e =>{
      setTagCheck(e.target.value)
    }

    const handleUpload = () => {
      if (!file) {
          toast.info('👿👿👿 Please upload an image...', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
      }

      const storageRef = ref(storage, `/files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed",(snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setPercent(percent);
      },(err) => console.log(err),() => {
        getDownloadURL(uploadTask.snapshot.ref).then((res) => {
          setUrls(res);
          console.log(res);
        });
      });
    }

    useEffect(() => {
      refreshToken();
      getCategories();
    }, []);
    
    async function getCategories () {
        try{
          let url = 'https://app.thepinkindex.com/subcategories';
          const postData = {categories : categories, provider: name};
          const data = await axios.post(url,postData);
          let cat = [];
          data.data.forEach((res) => {
            cat.push({
              key: res.subcat_name,
              value: res.id,
            })
          })
          setCate([
            { key: 'Select Appointment', value:''},
            ...cat
          ])
        }catch(err){
          console.log(err)
        }
      }
  
      const refreshToken = async () => {
        try {
            const response = await axios.get('https://app.thepinkindex.com/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.expire);
        } catch (error) {
            if (error.response) {
              history.push("/");
            }
        }
      }
  
      const axiosJWT = axios.create();
  
      axiosJWT.interceptors.request.use(async (config) => {
          const currentDate = new Date();
          if (expire * 1000 < currentDate.getTime()) {
              const response = await axios.get('https://app.thepinkindex.com/token');
              config.headers.Authorization = `Bearer ${response.data.accessToken}`;
              setToken(response.data.accessToken);
              const decoded = jwt_decode(response.data.accessToken);
              setName(decoded.name);
              setExpire(decoded.expire);
          }
          return config;
      }, (error) => {
          return Promise.reject(error);
      });

      const Auth = async (e) => {
        e.preventDefault()
        try {
          let feec = feeCondition.$H+'Hours '+feeCondition.$m+'Minutes';
          await axios.post('https://app.thepinkindex.com/service/setup', {
            spid: name,
            cateid: categories,
            subcateid: subCategory,
            fcondition: feec,
            fcharge: feeChargeable,
            tag: tagcheck,
            url: urls
          });
          history.push("/provider/service/index");
        } catch (error) {
          if (error.response) {
            toast.info('👿👿👿' + error.response.data.msg, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
          }
        }
      }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100 mt-24">
        <Navbar />
        {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
        <section className="relative py-20 w-full">
          <div className="container mx-auto px-4 w-full">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded mt-12">
              <div className="rounded-t mb-0 px-4 py-3 border-0 bg-gray-900">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-white uppercase">
                      Appointment Set-up
                    </h3>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full">
                  <div className="mt-6 rounded overflow-hidden shadow-lg w-full bg-white">
                      <form className="w-full" onSubmit={Auth}>
                            <div className="flex flex-wrap -mx-3 mb-6 ml-4 w-full">
                              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                      Select Category
                                  </label>
                                  <select value={categories} onChange={(e)=>setCategories(e.target.value)} onBlur={getCategories} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                      <option value="" selected>Select Category</option>
                                      <option value="1">Body</option>
                                      <option value="2">Face</option>
                                      <option value="3">Hair</option>
                                  </select>
                              </div>
                              <div className="w-full md:w-1/3 px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                      Select Appointment
                                  </label>
                                  <select value={subCategory} onChange={(e)=>setSubCategory(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                      {cate.map((option) => {
                                        return (
                                          <option key={option.value} value={option.value}>
                                            {option.key}
                                          </option>
                                        );
                                      })}
                                  </select>
                              </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6 ml-4 w-full">
                              <div className="w-full md:w-1/2 px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                      Time to complete
                                  </label>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DemoContainer components={['TimePicker']}>
                                        <TimePicker views={['hours', 'minutes']} format="HH:mm" value={feeCondition} onChange={(newFeeCondition)=>setFeeCondition(newFeeCondition)} />
                                      </DemoContainer>
                                  </LocalizationProvider>
                              </div>
                              <div className="w-full md:w-1/3 px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                      Cost
                                  </label>
                                  <input value={feeChargeable} onChange={(e)=>setFeeChargeable(e.target.value)} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" />
                              </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6 ml-4 w-full">
                              <div className="w-full md:w-1/2 px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                      Select Tags
                                  </label>
                                  <input type="radio" value="Mobile" checked={tagcheck === "Mobile"} onChange={onOptionChange} className="mr-1 bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                                  <span className="mr-2 text-gray-700 text-xs font-bold uppercase">Mobile Only</span>
                                  <input type="radio" value="Modest" checked={tagcheck === "Modest"} onChange={onOptionChange} className="mr-1 bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                                  <span className="mr-2 text-gray-700 text-xs font-bold uppercase">Modest Only</span>
                                  <input type="radio" value="All of above" checked={tagcheck === "All of above"} onChange={onOptionChange} className="mr-1 bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                                  <span className="mr-2 text-gray-700 text-xs font-bold uppercase">Both</span>
                              </div>
                              <div className="w-full md:w-1/3 px-3">
                                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                      Add Appointment Images
                                  </label>
                                  <input type="file" onBlur={handleUpload} onChange={handleChange} accept="/image/*" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full pl-10"/>
                              </div>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6 ml-4 w-full">
                              <div className="w-full md:w-1/2 px-3">
                                <input
                                  className="bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                  type="submit"
                                  value="Submit"
                                  style={{ transition: "all .15s ease" }}
                                />
                              </div>
                            </div>
                      </form>
                      <p>{percent} "% done"</p>
                  </div> 
              </div>
            </div>
          </div>
        </section>
        </div>
      </div>
    </>
  );
}
