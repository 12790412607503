import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Navbar from "components/provider/Navbar.js";
import Footer from "components/Footer";
import Picture1 from "assets/img/nails.jpeg";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useHistory } from "react-router";

export default function ProviderPage() {
    const params = useParams()
    const [userid, setUserId] = useState([])
    const [name, setName] = useState('')
    const [token, setToken] = useState('')
    const [expire, setExpire] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [bookDate, setBookDate] = useState('')
    const [comment, setComment] = useState('')
    const [showModal, setShowModal] = React.useState(false);
    const [openTab, setOpenTab] = React.useState(1);
    const [policy, setPolicy] = useState([])
    const [getaddons, setGetAddons] = useState([])
    const [getprovidername, setGetProviderName] = useState('')
    const [val, setVal] = useState([])
    const [ref, setRef] = useState('')
    const history = useHistory();
    const color = 'pink';
            
    useEffect(() => {
      refreshToken();
      getProviderInfo();
      getProviderPolicy();
      getProviderAdditionalService();
    }, [getprovidername]);

    async function getProviderInfo () {
      try{
        let url = 'https://app.thepinkindex.com/user/info/'+params.id;
        const data = await axios.get(url);
        let rgt = data.data[0].sp_id;
        let sss = data.data[0].subcat_name;
        setGetProviderName(rgt);
        setSubCategory(sss);
        setUserId(data.data);
        setRef(Math.floor(1000000 + Math.random() * 9000000));
      }catch(err){
        console.log(err)
      }
    }

    async function getProviderPolicy () {
      try{
        let url = 'https://app.thepinkindex.com/provider/policy/'+getprovidername;
        const data = await axios.get(url);
        setPolicy(data.data);
      }catch(err){
        console.log(err)
      }
    }

    async function getProviderAdditionalService () {
      try{
        let url = 'https://app.thepinkindex.com/provider/addons/service/'+getprovidername;
        const data = await axios.get(url);
        setGetAddons(data.data);
      }catch(err){
        console.log(err)
      }
    }

    const refreshToken = async () => {
      try {
          const response = await axios.get('https://app.thepinkindex.com/token');
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setName(decoded.name);
          setExpire(decoded.expire);
      } catch (error) {
          if (error.response) {
            //history.push("/");
          }
      }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://app.thepinkindex.com/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleAdd = () => {
      const abc = [...val, []]
      setVal(abc)
    }

    const handleRemove = (x) => {
      const removeEle = [...val]
      removeEle.splice(x,1)
      setVal(removeEle)
    }

    const handleChange = (onChangeValue, i) => {
      const seldata = [...val]
      seldata[i] = onChangeValue.target.value;
      setVal(seldata)
    }
    
    const Auth = async (e)=>{
      e.preventDefault()
      try{
        const postData = { sid: params.id,
          subCategory: subCategory,
          spid: getprovidername,
          clientid: name,
          bookDate: bookDate,
          ref: ref,
          status: 'Pending',
          payment_status: 'Not Paid',
          comment: comment,
          addon: val 
        };
        console.log(postData);
        await axios.post('https://app.thepinkindex.com/service/register', postData);
        history.push("/service/provider/"+params.id);
      }catch(error){
        if (error.response) {
          toast.info('👿👿👿' + error.response.data.msg, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      }
    }
  return (
    <>
      <main>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="flex flex-wrap px-4 py-4">
        <div className="w-full">
        <h1 className="text-gray-700 font-semibold text-4xl">
                    {
                      userid.map((user, index) => (
                        user.sp_id
                      ))
                    } Schedule Page
                  </h1>
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Booking
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Services
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                 Policy
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 4
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                Additional Service
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className="mb-2">
                    {
                      userid.map((user, index) => (
                        <div>
                          <div className="max-w-sm w-full lg:max-w-full lg:flex">
                            <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style={{ backgroundImage: `url(${user.url})` }} title="Woman holding a mug">
                            </div>
                            <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                              <div className="mb-8">
                                <p className="text-sm text-gray-600 flex items-center">
                                  <svg className="fill-current text-gray-500 w-3 h-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                                  </svg>
                                  Appointment Booking
                                </p>
                                <h3 className="text-gray-700 text-base font-semibold">{ user.subcat_name }</h3>
                                <i className="far fa-clock text-gray-500 mr-1"></i>{ user.fee_condition } &nbsp;
                                <i className="fas fa-pound-sign text-gray-500 mr-1"></i>{ user.fee_chargeable } &nbsp;
                                <p><i className="fas fa-receipt text-gray-500 mr-1"></i> #{ref}</p>
                                <p><i className="fa fa-user text-gray-500 mr-1"></i> Client Name: {name}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    } 
                  </div>
                  <button
                    className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(true)}
                  >
                    Book Service
                  </button> 
                  {showModal ? (

                    name === ''
                    ?
                    <>
                      <div className="text-3xl font-semibold my-6 mx-auto">
                        Please Login or Signup To Book Appointment
                      </div>
                    </>
                    :
                    <>
                      <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                      >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                          {/*content*/}
                          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                              <h3 className="text-3xl font-semibold">
                                Submit Appointment
                              </h3>
                              <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                              >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                  ×
                                </span>
                              </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                              <form onSubmit={Auth}>
                                {
                                  userid.map((user, index) => (
                                    <div>
                                      <div className="max-w-sm w-full lg:max-w-full lg:flex">
                                        <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style={{ backgroundImage: `url(${user.url})` }} title="Woman holding a mug">
                                        </div>
                                        <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                                          <div className="mb-8">
                                            <h3 className="text-gray-700 text-base font-semibold">{ user.subcat_name }</h3>
                                            <i className="far fa-clock text-gray-500 mr-1"></i>{ user.fee_condition } &nbsp;
                                            <i className="fas fa-pound-sign text-gray-500 mr-1"></i>{ user.fee_chargeable }
                                            <p><i className="fas fa-receipt text-gray-500 mr-1"></i> #{ref}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                                <hr />
                                  {
                                    val.map((data,x) => {
                                      return(
                                        <div>
                                          <select value={data} onChange={e=>handleChange(e,x)} className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                            <option>Select Service</option>
                                            {
                                              getaddons.map((val, index) => (
                                                <option value={val.id}>{ val.name } { val.amount }</option>
                                              ))
                                            }
                                          </select>
                                          <button onClick={()=>handleRemove(x)}>x</button>
                                        </div>
                                      )
                                    })
                                  }
                                <hr/>
                                <div>
                                  <a href="#" onClick={()=>handleAdd()} className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400 inline-flex items-center justify-center"><i className="fas fa-plus text-gray-500 mr-1"></i>Add Additonal Service</a>
                                </div>
                                <hr />
                                <div className="relative w-full mb-3">
                                  <input
                                    type="date"
                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                    placeholder="Select date"
                                    value={bookDate}
                                    onChange={(e) => setBookDate(e.target.value)}
                                    style={{ transition: "all .15s ease" }}
                                  />
                                </div>
                                <div>
                                    <textarea
                                      rows="5"
                                      cols="15"
                                      placeholder="Enter comment"
                                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                      value={comment}
                                      onChange={(e) => setComment(e.target.value)}
                                      style={{ transition: "all .15s ease" }}
                                    >
                                    </textarea>
                                </div>
                                <div className="text-center mt-6">
                                  <input
                                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                                    type="submit"
                                    value="Submit"
                                    style={{ transition: "all .15s ease" }}
                                  />
                                </div>
                              </form>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                              <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModal(false)}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                  ) : null}

                  <FullCalendar
                    headerToolbar={{
                      start: "prev,next",
                      center: "title",
                      end: "dayGridMonth dayGridWeek dayGridDay"
                    }} 
                    plugins={[daygridPlugin]}
                    events={
                      {
                        url: 'https://app.thepinkindex.com/service/event/'+getprovidername,
                        method: 'GET',
                        failure: function(){
                          console.log('there was an error while fetchng events');
                        },
                        color: 'orange'
                      }  
                    }
                  />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                <section className="relative">
                    <div className="container mx-auto px-4">
                      <div className="flex flex-wrap">
                        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                          <article className="overflow-hidden rounded-lg shadow-lg">
                            <a href="#">
                              <img alt="Placeholder" className="block h-auto w-full" src="https://picsum.photos/400/300/?random" />
                            </a>
                            <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                              <p className="ml-2 text-sm">Service Provider Name </p>
                            </footer>
                          </article>
                        </div>

                        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                          <article className="overflow-hidden rounded-lg shadow-lg">
                            <a href="#">
                              <img alt="Placeholder" className="block h-auto w-full" src="https://picsum.photos/400/300/?random" />
                            </a>

                            <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                              <p className="ml-2 text-sm">Service Provider Name </p>
                            </footer>
                          </article>
                        </div>

                        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                          <article className="overflow-hidden rounded-lg shadow-lg">
                            <a href="#">
                              <img alt="Placeholder" className="block h-auto w-full" src="https://picsum.photos/400/300/?random" />
                            </a>

                            <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                              <p className="ml-2 text-sm">Service Provider Name </p>
                            </footer>
                          </article>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <div>
                    {
                      policy.map((data, index) => (
                        <div className="rounded item-center shadow-lg">
                          <img alt="Provider Policy" className="rounded-t-lg" src={data.filename} />
                        </div>
                      ))
                    } 
                  </div>
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <div>
                    <h5 className="text-gray text-center item-center font-semibold text-3xl uppercase">Additional Services</h5>
                    {
                      getaddons.map((data, index) => (
                        <div>
                            <div className="max-w-sm rounded overflow-hidden shadow-lg">
                              <div className="px-6 py-4">
                                <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"><i className="far fa-edit text-gray-500 mr-1"></i> { data.name }</span>
                                <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"><i className="fas fa-pound-sign text-gray-500 mr-1"></i> { data.amount }</span>
                              </div>
                            </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </main>
      <Footer />
    </>
  );
}
