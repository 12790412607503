import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import Picture1 from "assets/img/marcus-lewis-csKAiMU1OrU-unsplash.jpg";
import axios from 'axios';

export default function Landing() {
  const [service, setService] = useState([])

  useEffect(() => {
    getSetup();
  }, []);

  async function getSetup () {
    try{
      let url = 'https://app.thepinkindex.com/service/random';
      const data = await axios.get(url);
      console.log(data);
      setService(data.data);
    }catch(err){
      console.log(err)
    }
  }

  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
            style={{
              minHeight: "75vh"
            }}>
          
          <div className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${Picture1})`
              }}>
            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
          </div>
          <div className="container relative mx-auto">
              <div className="items-center flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                  <div className="pr-12">
                    <h1 className="text-white font-semibold text-5xl">
                      Your story starts with us.
                    </h1>
                    <br/><br/>
                    <a
                      href="/service/category" 
                      className="bg-gray-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1">
                      Book Now
                    </a>
                  </div>
                </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
            </div>
        </div>
        <section className="pb-20 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-clock"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Availability</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Find appointments on for the date and time you want
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-gray-500">
                      <i className="far fa-heart"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      For You
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Stylist which can cater to you
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fab fa-searchengin"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Specific Search
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Filter for area, distance, price and style
                    </p>
                    <a 
                      href="#"
                      className="bg-blue-500 text-white active:bg-blue-700 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                      type="button">
                        view
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-12">
          <div className="container mx-auto px-4">
            <h6 className="text-2xl font-bold px-4"><i className="far fa-compass text-gray-500 mr-1"></i> Explore</h6>
            <div className="flex flex-wrap">
              {
                service.map((sr, index) => (                
                  <div className="max-w-sm items-center align-center bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mr-8 mb-12 ml-16 mt-2">
                      <Link to={`/service/provider/${sr.id}`}>
                        <img alt="" className="rounded-t-lg" src={sr.url} width="300px" height="150px" />
                      </Link>
                      <div className="p-5">
                        <Link to={`/service/provider/${sr.id}`}>
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{sr.subcat_name}</h5>
                            <h5 className="mb-2 text-xl tracking-tight text-gray-900 dark:text-white">{sr.sp_id}</h5>
                        </Link>
                        <div className="flex items-center p-0 md:p-2 text-sm">
                          <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"><i className="fas fa-map-marker-alt text-gray-500 mr-1"></i>{ sr.tag }</span>
                          <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"><i className="fas fa-pound-sign text-gray-500 mr-1"></i>{ sr.fee_chargeable }</span>
                        </div>
                        <div className="flex items-center p-0 md:p-2 text-sm">
                          <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"><i className="far fa-clock text-gray-500 mr-1"></i>{ sr.fee_condition }</span> &nbsp;
                          <Link to={`/service/provider/${sr.id}`} className="bg-gray-500 text-white active:bg-blue-700 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1">
                            Book Now
                          </Link>
                        </div>
                      </div>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
