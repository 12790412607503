import React from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const params = useParams()
  const history = useHistory()

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/service/payment`,
      },
      redirect: "if_required",
    });

    if(error){
      setMessage(error.message);
    } else if(paymentIntent && paymentIntent.status === "succeeded"){
      //setMessage("Payment Status: " + paymentIntent.status);
      history.push(`/payment/service/completion/${params.pid}/${paymentIntent.id}`);
    }else{
      setMessage("Unexpected error");
    }
    setIsProcessing(false);
  };

  return (
    <>
      <div className="container mx-auto px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded mt-12">
          <div className="block w-full">
            <form id="payment-form" onSubmit={handleSubmit}>
              <PaymentElement />
              <button disabled={isProcessing || !stripe || !elements} id="submit" className="bg-pink-500 mt-2 text-white font-bold active:bg-pink-700 text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-4 ml-4">
                <span id="button-text">
                  {isProcessing ? "Processing ... " : "Pay now"}
                </span>
              </button>
              {/* Show any error or success messages */}
              {message && <div id="payment-message">{message}</div>}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
