import React, {useState, useEffect} from "react";
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [name, setName] = useState('')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const [data, setData] = useState({slug: "", results: []});

  useEffect(() => {
    refreshToken();
    search();
  }, [data.slug]);

  const search = async () => {
    if (data.slug !== "") {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          try {
            alert('Working on search content ' + data.slug);
            //const res = await axios.get('http://164.92.188.252:5000/search/service/'+data.slug);
            //console.log("search service "+res);
            //setData({ ...data, results: res.data });
          } catch (err) {
            console.error(err);
          }
        };
        fetch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }

  const refreshToken = async () => {
    try {
        const response = await axios.get('https://app.thepinkindex.com/token');
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.expire);
    } catch (error) {
        if (error.response) {
          //history.push("/");
          console.log(error.response);
        }
    }
  }

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get('https://app.thepinkindex.com/token');
          config.headers.Authorization = `Bearer ${response.data.accessToken}`;
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setName(decoded.name);
          setExpire(decoded.expire);
      }
      return config;
  }, (error) => {
      return Promise.reject(error);
  });

  return (
    <nav
      className={
        (props.transparent
          ? "top-0 absolute z-50 w-full"
          : "relative shadow-lg bg-white shadow-lg") +
        " flex flex-wrap items-center justify-between px-2 py-3 "
      }
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a
            className={
              (props.transparent ? "text-pink-500" : "text-gray-800") +
              " text-xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            }
            href="/"
          >
            The Pink Index
          </a>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i
              className={
                (props.transparent ? "text-white" : "text-gray-800") +
                " fas fa-bars"
              }
            ></i>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
            (navbarOpen ? " block rounded shadow-lg" : " hidden")
          }
          id="example-navbar-warning"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            {
              name !== '' 
              ? <>
                  <li className="flex items-center">
                    <a href="/provider/dashboard" className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 uppercase font-bold">
                      Welcome ::  { name }
                    </a>
                  </li>
                </> 
              : <>
                  <li className="flex items-center">
                    <a href="/login" className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4">
                      Login
                    </a>
                  </li>
                  <li className="flex items-center">
                    <a href="/user/register" className="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4">
                      Join Us
                    </a>
                  </li>
                </>
              
            }
            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="#pablo"
              >
                <i
                  className={
                    (props.transparent
                      ? "lg:text-gray-300 text-gray-500"
                      : "text-gray-500") +
                    " fab fa-facebook text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">pink FB</span>
              </a>
            </li>

            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="#pablo"
              >
                <i
                  className={
                    (props.transparent
                      ? "lg:text-gray-300 text-gray-500"
                      : "text-gray-500") +
                    " fab fa-twitter text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">Pink Twitter</span>
              </a>
            </li>

            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="#pablo"
              >
                <i
                  className={
                    (props.transparent
                      ? "lg:text-gray-300 text-gray-500"
                      : "text-gray-500") +
                    " fab fa-instagram text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">Pink Instagram</span>
              </a>
            </li>

            <li className="flex items-center">
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                placeholder="Search for service..."
                style={{ transition: "all .15s ease" }}
                value={data.slug}
                onChange={(e) => setData({ ...data, slug: e.target.value })}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
