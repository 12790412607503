import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Picture1 from "assets/img/ziphaus-gexSJRjb0qw-unsplash.jpeg";
import Picture2 from "assets/img/background.jpg";
import Picture3 from "assets/img/D6017548-34A5-4B74-AC92-AD0F2424F45E_1_201_a.jpeg";
import Eye from "img/eyelashes.png";
import Nail from "img/nails.png";
import Makeup from "img/makeup.webp";
import Wig from "img/wigs.png";
import Braid from "img/braided.png";
import Ear from "img/ear.png";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const libraries = ['places'];
const mapContainerStyle = {
    width: '100vw',
    height: '100vh',
};
const center = [];
const settings = {
    dots: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
  };

export default function HomeTest() {
    const [name, setName] = useState('')
    const [token, setToken] = useState('')
    const [expire, setExpire] = useState('')
    const [service, setService] = useState([])
    const [premium, setPremium] = useState([])
    const [county, setCounty] = useState([])

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "assets/main.js";
        script.src = "assets/easing/easing.min.js";
        script.src = "assets/owlcarousel/owl.carousel.min.js";
        script.src = "tempusdominus/js/moment.min.js";
        script.src = "tempusdominus/js/moment-timezone.min.js";
        script.src = "tempusdominus/js/tempusdominus-bootstrap-4.min.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        getLocation();
        getSetup();
        getPremiumProvider();
        getProviderCountry();
        refreshToken();
    }, []);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBiN2rHBXw9p-PTKr1jYxLkYFDulIbRwYk",
        libraries,
    });

    async function getLocation(){
        let url = 'https://app.thepinkindex.com/provider/location';
        const data = await axios.get(url);
        console.log(data);
        let x = 1;
        data.data.forEach((res) => {
            center.push({
                id: x,
                position: {lat: parseFloat(res.lat), lng: parseFloat(res.lng)},
            });
            x++;
        });
    }

    const onLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        center.forEach(({position}) => bounds.extend(position));
        map.fitBounds(bounds);
    }

    async function getSetup () {
        try{
            let url = 'https://app.thepinkindex.com/service/random';
            const data = await axios.get(url);
            setService(data.data);
        }catch(err){
            console.log(err)
        }
    }

    async function getPremiumProvider (){
        try{
            let url = 'https://app.thepinkindex.com/premium/service/provider';
            const data = await axios.get(url);
            setPremium(data.data);
        }catch(err){
            console.log(err);
        }
    }

    async function getProviderCountry (){
        try{
            let url = 'https://app.thepinkindex.com/country/service/provider';
            const data = await axios.get(url);
            setCounty(data.data);
        }catch(err){
            console.log(err);
        }
    }

    const refreshToken = async () => {
        try {
            const response = await axios.get('https://app.thepinkindex.com/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.expire);
        } catch (error) {
            if (error.response) {
              console.log(error.response);
            }
        }
    }
    
    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('https://app.thepinkindex.com/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

  return (
    <>
    <body>
    <div className="container-fluid bg-light pt-3 d-none d-lg-block">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
                    <div className="d-inline-flex align-items-center">
                        <p><i className="fa fa-envelope mr-2"></i>info@thepinkindex.com</p>
                    </div>
                </div>

                {
                    name !== '' 
                    ? 
                    <>
                        <div className="col-lg-6 text-center text-lg-right">
                            <div className="d-inline-flex align-items-center">
                                <a className="text-pink px-3" href="/provider/dashboard">
                                    <i className="fa fa-user"></i> Welcome ::  { name }
                                </a>
                            </div>
                        </div>
                    </> 
                    : 
                    <>
                        <div className="col-lg-6 text-center text-lg-right">
                            <div className="d-inline-flex align-items-center">
                                <a className="text-pink px-3" href="/login">
                                    <i className="fa fa-lock"></i> Login
                                </a>
                                <a className="text-pink px-3" href="/register">
                                    <i className="fa fa-user"></i> Sign Up
                                </a>
                            </div>
                        </div>
                    </>
                
                }
            </div>
        </div>
    </div>
   
    <div className="container-fluid position-relative nav-bar p-0">
        <div className="container-lg position-relative p-0 px-lg-3" style={{zIndex: "9" }}>
            <nav className="navbar navbar-expand-lg bg-light navbar-light shadow-lg py-3 py-lg-0 pl-3 pl-lg-5">
                <a href="" className="navbar-brand">
                    <h1 className="m-0 text-primary"><span className="text-dark">Pink</span>Index</h1>
                </a>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
                    <div className="navbar-nav ml-auto py-0">
                        <a href="#" className="nav-item nav-link active">Home</a>
                        <a href="#" className="nav-item nav-link">About</a>
                        <a href="#" className="nav-item nav-link">Services</a>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Pages</a>
                            <div className="dropdown-menu border-0 rounded-0 m-0">
                                <a href="#" className="dropdown-item">Blog Grid</a>
                                <a href="#" className="dropdown-item">Blog Detail</a>
                                <a href="#" className="dropdown-item">Destination</a>
                                <a href="#" className="dropdown-item">Travel Guides</a>
                                <a href="#" className="dropdown-item">Testimonial</a>
                            </div>
                        </div>
                        <a href="#" className="nav-item nav-link">Contact</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div className="container-fluid p-0">
        <div id="header-carousel" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100" src={ Picture1 } alt="Image" />
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div className="p-3" style={{ maxWidth: "900px"}} >
                            <h4 className="text-white text-uppercase mb-md-3">Experience beauty in style</h4>
                            <h1 className="display-3 text-white mb-md-4">Unlock Your Appointment Potential With Us</h1>
                            <a href="/service/category" className="btn btn-primary py-md-3 px-md-5 mt-2">Book Now</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src={ Picture2 } alt="Image" />
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div className="p-3" style={{ maxWidth: "900px"}}>
                            <h4 className="text-white text-uppercase mb-md-3">Beauty at your door step</h4>
                            <h1 className="display-3 text-white mb-md-4">Explore Top-Rated Services Near You Here</h1>
                            <a href="/service/category" className="btn btn-primary py-md-3 px-md-5 mt-2">Book Now</a>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src={ Picture3 } alt="Image" />
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div className="p-3" style={{ maxWidth: "900px"}}>
                            <h4 className="text-white text-uppercase mb-md-3">Top Notch Service</h4>
                            <h1 className="display-3 text-white mb-md-4">Experience Premium Services Now</h1>
                            <a href="/service/category" className="btn btn-primary py-md-3 px-md-5 mt-2">Book Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <a className="carousel-control-prev" href="#header-carousel" data-slide="prev">
                <div className="btn btn-dark" style={{ width: "45px", height: "45px" }} >
                    <span className="carousel-control-prev-icon mb-n2"></span>
                </div>
            </a>
            <a className="carousel-control-next" href="#header-carousel" data-slide="next">
                <div className="btn btn-dark" style={{ width: "45px", height: "45px" }} >
                    <span className="carousel-control-next-icon mb-n2"></span>
                </div>
            </a>
        </div>
    </div>

    <div className="container-fluid booking mt-5 pb-5">
        <div className="container pb-5">
            <div className="bg-light shadow" style= {{ padding: "30px" }} >
                <div className="row align-items-center" style={{ minHeight: "60px" }}>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-2 items-center">
                                <div className="md-2 mb-md-0">
                                    <a href="/service/categories/face">
                                        <img src={Eye} alt="Eyelashe" style={{width: "100%", height: "100%", maxHeight: "80px", maxWidth: "80px"}} />
                                        <p>Eyelashe</p>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-2 items-center">
                                <div className="mb-2 mb-md-0">
                                    <a href="/service/categories/body">
                                        <img src={Nail} alt="Nail" style={{width: "100%", height: "100%", maxHeight: "80px", maxWidth: "80px"}} />
                                        <p>Nails</p>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-2 items-center">
                                <div className="mb-2 mb-md-0">
                                    <a href="/service/categories/face">
                                        <img src={Makeup} alt="Makeup" style={{width: "100%", height: "100%", maxHeight: "80px", maxWidth: "80px"}} />
                                        <p>Makeup</p>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-2 items-center">
                                <div className="mb-2 mb-md-0">
                                    <a href="/service/categories/hair">
                                        <img src={Wig} alt="Wigs" style={{width: "100%", height: "100%", maxHeight: "80px", maxWidth: "80px"}} />
                                        <p>Wigs</p>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-2 items-center">
                                <div className="mb-2 mb-md-0">
                                    <a href="/service/categories/hair">
                                        <img src={Braid} alt="Braid" style={{width: "100%", height: "100%", maxHeight: "80px", maxWidth: "80px"}} />
                                        <p>Braids</p>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-2 items-center">
                                <div className="mb-2 mb-md-0">
                                    <a href="/service/categories/hair">
                                        <img src={Ear} alt="Braid" style={{width: "100%", height: "100%", maxHeight: "80px", maxWidth: "80px"}} />
                                        <p>Ear Piercing</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid py-5">
        <div className="container pt-5 pb-3">
            <div className="text-center mb-3 pb-3">
                <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Findout</h6>
                <h1>How It Works</h1>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="service-item bg-white text-center mb-2 py-5 px-4">
                        <a href="/service/category">
                            <i className="fa fa-2x fa-route mx-auto mb-4"></i>
                            <h5 className="mb-2">Choose a Category</h5>
                        </a>
                        <p className="m-0">Explore Service Categories</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="service-item bg-white text-center mb-2 py-5 px-4">
                        <i className="fa fa-2x fa-ticket-alt mx-auto mb-4"></i>
                        <h5 className="mb-2">Find What You Want</h5>
                        <p className="m-0">Locate Your Preferred Service</p>
                        <p className="m-0">Spefice service names, so you can find the exact service you are looking for</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="service-item bg-white text-center mb-2 py-5 px-4">
                        <i className="fa fa-2x fa-hotel mx-auto mb-4"></i>
                        <h5 className="mb-2">Go Out & Explore</h5>
                        <p className="m-0">Schedule Your Service</p>
                        <p className="m-0">Find a date and time that works for you</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid py-5">
        <div className="container pt-5">
            <div className="row">
                {
                    !isLoaded ? (
                        <h1>Loading...</h1>
                    ) : (
                        <GoogleMap zoom={10} mapContainerStyle={mapContainerStyle} onLoad={onLoad}>
                            {
                                center.map(({id, position}) => (
                                    <Marker key={id} position={position} />
                                ))
                            } 
                        </GoogleMap>
                    )
                }
            </div>
        </div>
    </div>

    <div className="container-fluid py-5">
        <div className="container pt-5 pb-3">
             <div className="text-center mb-3 pb-3">
                <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Top Notch Provider</h6>
                <h1>Explore Premium Service Provider</h1>
            </div>
            <Slider {...settings}>
                {
                    premium.map((sr, index) => (
                        <div className="container-fluid py-5">
                            <div className="container pt-5">
                                <div className="row">
                                    <div className="col-lg-6" style={{ minHeight: "300px" }} >
                                        <div className="position-relative h-100">
                                            <img className="position-absolute w-100 h-100" src={sr.img} style={{ objectFit: "cover" }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 pt-5 pb-lg-5">
                                        <div className="about-text bg-white p-4 p-lg-5 my-lg-5">
                                            <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Premium Service</h6>
                                            <h1 className="mb-3">{ sr.name }</h1>
                                            <p>{ sr.bio }</p>
                                            <Link to={`/provider/explore/details/${sr.id}`}>
                                                <a className="btn btn-primary mt-1">Explore Page</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </Slider>
        </div>
    </div>
    
    <div className="container-fluid py-5">
        <div className="container pt-5 pb-3">
            <div className="text-center mb-3 pb-3">
                <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Popular Service Provider</h6>
                <h1>Explore Top Provider</h1>
            </div>
            <div className="row">
                {
                    county.map((sr, index) =>(
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="destination-item position-relative overflow-hidden mb-2">
                                <img className="img-fluid" src={ Picture1 } alt="" />
                                <a className="destination-overlay text-white text-decoration-none" href="">
                                    <h5 className="text-white">{ sr.country }</h5>
                                    <span>100+ Cities</span>
                                </a>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>

    <div className="container-fluid py-5">
        <div className="container pt-5 pb-3">
            <div className="text-center mb-3 pb-3">
                <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Services</h6>
                <h1>Latest Post</h1>
            </div>
            <div className="row">
                {
                    service.map((sr, index) => (
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="package-item bg-white mb-2">
                                <Link to={`/service/provider/${sr.id}`}>
                                    <img className="img-fluid" src={sr.url} alt="" style={{width: "100%", height: "80%", maxHeight: "350px", maxWidth: "350px"}} />
                                </Link>
                                <div className="p-4">
                                    <div className="d-flex justify-content-between mb-3">
                                        <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>location</small>
                                        <small className="m-0"><i className="fa fa-clock text-primary mr-2"></i>{ sr.fee_condition }</small>
                                        <small className="m-0"><i className="fa fa-cogs text-primary mr-2"></i>{ sr.tag }</small>
                                    </div>
                                    <Link to={`/service/provider/${sr.id}`}>
                                        <a className="h5 text-decoration-none" href="">{sr.subcat_name}</a>
                                        <h6 className="m-0">{sr.sp_id}</h6>
                                    </Link>
                                    <div className="border-top mt-4 pt-4">
                                        <div className="d-flex justify-content-between">
                                            <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5</h6>
                                            <h5 className="m-0"><i className="fa fa-pound-sign text-primary mr-2"></i>{ sr.fee_chargeable }</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
    
    <div className="container-fluid bg-registration py-5" style={{ margin: "90px 0" }}>
        <div className="container py-5">
            <div className="row align-items-center">
                <div className="col-lg-7 mb-5 mb-lg-0">
                    <div className="mb-4">
                        <h6 className="text-primary text-uppercase" style={{ letterSpacing: "5px" }}>Provider Signup</h6>
                    </div>
                    <p className="text-white">
                        Join The Pink Index today and become a part of our growing community of service providers. By signing up, you'll gain access to our platform, where you can showcase
                        your services and connect with clients looking for what you offer.
                    </p>
                    <p className="text-white">
                        Benefits of joining The Pink Index:
                    </p>
                    <ul className="list-inline text-white m-0">
                        <li className="py-2"><i className="fa fa-check text-primary mr-3"></i>Increase your visibility: Reach a wider audience of potential clients searching for services like yours.</li>
                        <li className="py-2"><i className="fa fa-check text-primary mr-3"></i>Manage your appointments: Easily schedule and manage your appointments through our user-friendly interface.</li>
                        <li className="py-2"><i className="fa fa-check text-primary mr-3"></i>Boost your business: Utilize our tools and resources to grow your business and enhance your online presence.</li>
                        <li className="py-2"><i className="fa fa-check text-primary mr-3"></i>Connect with clients: Build realtionships with clients and establish yourself as a trusted service provider in your area.</li>
                    </ul>
                </div>
                <div className="col-lg-5">
                    <div className="card border-0">
                        <div className="card-header bg-primary text-center p-4">
                            <h1 className="text-white m-0">Sign Up Now</h1>
                        </div>
                        <div className="card-body rounded-bottom bg-white p-5">
                            <form>
                                <div>
                                    <a className="btn btn-primary btn-block py-3" href="/register">Continue</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-lg-5" style={{ marginTop: "90px" }}>
        <div className="row pt-5">
            <div className="col-lg-3 col-md-6 mb-5">
                <a href="" className="navbar-brand">
                    <h1 className="text-primary"><span className="text-white">Pink</span>Index</h1>
                </a>
                <p>Sed ipsum clita tempor ipsum ipsum amet sit ipsum lorem amet labore rebum lorem ipsum dolor. No sed vero lorem dolor dolor</p>
                <h6 className="text-white text-uppercase mt-4 mb-3" style={{ letterSpacing: "5px" }}>Follow Us</h6>
                <div className="d-flex justify-content-start">
                    <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                    <a className="btn btn-outline-primary btn-square" href="#"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Popular Categories</h5>
                <div className="d-flex flex-column justify-content-start">
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>About</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Destination</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Services</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Packages</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Guides</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Testimonial</a>
                    <a className="text-white-50" href="#"><i className="fa fa-angle-right mr-2"></i>Blog</a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Links</h5>
                <div className="d-flex flex-column justify-content-start">
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>About</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Destination</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Services</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Packages</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Guides</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Testimonial</a>
                    <a className="text-white-50" href="#"><i className="fa fa-angle-right mr-2"></i>Blog</a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Contact Us</h5>
                <p><i className="fa fa-map-marker-alt mr-2"></i>123 Street, New York, USA</p>
                <p><i className="fa fa-phone-alt mr-2"></i>+012 345 67890</p>
                <p><i className="fa fa-envelope mr-2"></i>info@thepinkindex.com</p>
                <h6 className="text-white text-uppercase mt-4 mb-3" style={{ letterSpacing: "5px" }}>Newsletter</h6>
                <div className="w-100">
                    <div className="input-group">
                        <input type="text" className="form-control border-light" style={{ padding: "25px" }} placeholder="Your Email" />
                        <div className="input-group-append">
                            <button className="btn btn-primary px-3">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5">
        <div className="row">
            <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
                <p className="m-0 text-white-50">Copyright &copy; <a href="#">thepinkindex.com</a>. All Rights Reserved.
                </p>
            </div>
            <div className="col-lg-6 text-center text-md-right">
                <p className="m-0 text-white-50">Designed by <a href="https://dcc2.com">Decode-Camp Consultant</a>
                </p>
            </div>
        </div>
    </div>
    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="fa fa-angle-double-up"></i></a>
    </body>
    </>
  );
}
