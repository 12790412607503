import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import { useHistory } from "react-router";
import axios from 'axios';

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [expire, setExpire] = useState('');
  const [users, setUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
      refreshToken();
      getUsers();
  }, []);

  const Logout = async () => {
    try {
        await axios.delete('https://app.thepinkindex.com/logout');
        //await axios.delete('http://localhost:5000/logout');
        history.push("/");
    } catch (error) {
        console.log(error);
    }
  }
  const refreshToken = async () => {
      try {
          const response = await axios.get('https://app.thepinkindex.com/token');
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setName(decoded.name);
          setExpire(decoded.expire);
      } catch (error) {
          if (error.response) {
              history.push("/");
          }
      }
  }

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get('https://app.thepinkindex.com/token');
          config.headers.Authorization = `Bearer ${response.data.accessToken}`;
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setName(decoded.name);
          setExpire(decoded.expire);
      }
      return config;
  }, (error) => {
      return Promise.reject(error);
  });

  const getUsers = async () => {
      const response = await axiosJWT.get('https://app.thepinkindex.com/users', {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      setUsers(response.data);
  }
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-gray-900 flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            The Pink Index
          </Link>
          
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    The Pink Index
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className="text-white hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/provider/dashboard"
                >
                  <i className="fas fa-tv text-white mr-2 text-sm"></i> Dashboard
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-white hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/provider/create/appointment"
                >
                  <i className="fas fa-cog text-blueGray-400 mr-2 text-sm"></i> Register Service
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-white hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/provider/service/index"
                >
                  <i className="fas fa-cog text-blueGray-400 mr-2 text-sm"></i> Setup Service
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-white hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/provider/upload/status/index"
                >
                  <i className="fas fa-camera-retro text-blueGray-400 mr-2 text-sm"></i> Post Status
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-white hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/provider/addons/create"
                >
                  <i className="fas fa-cart-plus text-blueGray-400 mr-2 text-sm"></i> Add-On Setup
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-white hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/provider/policy/upload"
                >
                  <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i> Add Policy
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-white hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/home"
                >
                  <i className="far fa-calendar-check text-blueGray-400 mr-2 text-sm"></i> Schedule Details
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-white hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/home"
                >
                  <i className="fas fa-print text-blueGray-400 mr-2 text-sm"></i> Reports
                </Link>
              </li>

              <li className="items-center">
                <button
                  type="button"
                  className="text-white hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  style={{ transition: "all .15s ease" }}
                  onClick={Logout}
                >
                  <i className="fas fa-sign-out-alt text-blueGray-400 mr-2 text-sm"></i> Sign out
                </button>
              </li>
            </ul>
            
            <ul className="flex-col md:flex-col list-none items-left hidden md:flex mr-1">
              <li className="inline-block relative text-blueGray-400 mr-2 text-sm">
                <i className="fas fa-map-marker-alt mr-2"></i>Location
              </li>
              <li className="inline-block relative text-blueGray-400 mr-2 text-sm">
                <i className="fas fa-mobile-alt mr-2"></i>Mobile
              </li>
              <li className="inline-block relative text-blueGray-400 mr-2 text-sm">
                <i className="fab fa-instagram mr-2"></i>Instagram
              </li>
              <li className="inline-block relative text-blueGray-400 mr-2 text-sm">
                <a href="" className="bg-pink-500 active:bg-gray-100 text-white font-normal px-1 py-0 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs">Verify Account</a>
              </li>
            </ul>
            <div class="flex items-center p-2 md:p-4 text-sm">
              Client Ratings
              <svg aria-hidden="true" className="w-5 h-5 text-yellow-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
              <svg aria-hidden="true" className="w-5 h-5 text-yellow-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
              <svg aria-hidden="true" className="w-5 h-5 text-yellow-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
              <svg aria-hidden="true" className="w-5 h-5 text-yellow-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
              <svg aria-hidden="true" className="w-5 h-5 text-yellow-500 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
              <span class="w-1 h-1 mx-1.5 bg-gold-500 rounded-full dark:bg-gray-400"></span>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
