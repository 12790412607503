import React, { useState } from "react";
import Navbar from "components/login/Navbar.js";
import FooterSmall from "components/login/FooterSmall.js";
import Picture1 from "assets/img/register_bg_2.png";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from "react-router";
import { useParams } from "react-router-dom/";

export default function ResetPassword() {

  const [pass, setPass] = useState('')
  const [cpass, setCPass] = useState('')
  const history = useHistory()
  const params = useParams()

  const Auth = async (e) => {
    e.preventDefault()
    try {
      let res = await axios.post('https://app.thepinkindex.com/reset/password', {
        userID: params.id,
        password: pass,
        confPass: cpass 
      })
      console.log(res);
      if(res.data.message === 'Success'){
        toast.success('Password Reset Successfully', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }); 
      }
      history.push('/login')
    } catch (error) {
      if (error.response) {
        toast.info('👿👿👿' + error.response.data.msg, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        })
      }
    }
  }
  return (
    <>
      <Navbar transparent />
      <main>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <section className="absolute w-full h-full">
          <div
            className="absolute top-0 w-full h-full bg-gray-900"
            style={{
              backgroundImage:`url(${Picture1})`,
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat"
            }}
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                  <div className="btn-wrapper text-center mr-4 mb-4">
                      Already to have account?
                      <a
                        href="/login"
                        className="bg-pink-500 active:bg-gray-100 text-white font-normal ml-2 px-1 py-0 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
                        style={{ transition: "all .15s ease" }}
                      >
                        Login
                      </a>
                    </div>
                    <div className="text-center mb-3">
                      <h6 className="text-gray-600 text-sm font-bold">
                        Reset Password Page
                      </h6>
                    </div>
                    <hr className="mt-6 border-b-1 border-gray-400" />
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form onSubmit={Auth}>
                      <div className="relative w-full mb-3">
                        <input
                          type="password"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Please enter new password"
                          value={pass}
                          onChange={(e) => setPass(e.target.value)}
                          style={{ transition: "all .15s ease" }}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <input
                          type="password"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Confirm new password"
                          value={cpass}
                          onChange={(e) => setCPass(e.target.value)}
                          style={{ transition: "all .15s ease" }}
                        />
                      </div>
                      <div className="text-center mt-6">
                        <input
                          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                          type="submit"
                          value="Submit"
                          style={{ transition: "all .15s ease" }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
