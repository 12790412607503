import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Picture1 from "assets/img/ziphaus-gexSJRjb0qw-unsplash.jpg";
import axios from 'axios';

export default function SinglePage(){
    const params = useParams()
    const [service, setService] = useState([])

    useEffect(() => {
        getSetup();
    }, []);

    async function getSetup () {
        try{
            let url = 'https://app.thepinkindex.com/service/random';
            const data = await axios.get(url);
            setService(data.data);
        }catch(err){
            console.log(err)
        }
    }

    return(
        <>
            <body>
                <div className="container-fluid bg-light pt-3 d-none d-lg-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
                                <div className="d-inline-flex align-items-center">
                                <p><i className="fa fa-envelope mr-2"></i>info@thepinkindex.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid position-relative nav-bar p-0">
                    <div className="container-lg position-relative p-0 px-lg-3" style={{ zIndex: "9"}}>
                        <nav className="navbar navbar-expand-lg bg-light navbar-light shadow-lg py-3 py-lg-0 pl-3 pl-lg-5">
                            <a href="" className="navbar-brand">
                                <h1 className="m-0 text-primary"><span className="text-dark">Pink</span>Index</h1>
                            </a>
                            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
                                <div className="navbar-nav ml-auto py-0">
                                    <a href="/" className="nav-item nav-link">Home</a>
                                    <a href="" className="nav-item nav-link">About</a>
                                    <a href="" className="nav-item nav-link">Contact</a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                
                <div className="container-fluid py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-4 mt-5 mt-lg-0">
                                <div className="d-flex flex-column text-center bg-white mb-5 py-5 px-4">
                                    <img src={ Picture1 } className="img-fluid mx-auto mb-3" style={{ width: "100px" }} />
                                    <h3 className="mb-3">Service Provider Business Name</h3>
                                    <p>Conset elitr erat vero dolor ipsum et diam, eos dolor lorem, ipsum sit no ut est  ipsum erat kasd amet elitr</p>
                                    <div className="d-flex justify-content-center">
                                        <a className="text-primary px-2" href="">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                        <a className="text-primary px-2" href="">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                        <a className="text-primary px-2" href="">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                        <a className="text-primary px-2" href="">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                        <a className="text-primary px-2" href="">
                                            <i className="fab fa-youtube"></i>
                                        </a>
                                    </div>
                                </div>
                    
                                <div className="mb-5">
                                    <h4 className="text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Tag</h4>
                                    <div className="d-flex flex-wrap m-n1">
                                        <a href="" className="btn btn-light m-1">View Policy</a>
                                        <a href="" className="btn btn-light m-1">View Schedule</a>
                                        <a href="" className="btn btn-light m-1">Book Service</a>
                                        <a href="" className="btn btn-light m-1">Leave Review</a>
                                        <a href="" className="btn btn-light m-1">Report Provider</a>
                                    </div>
                                </div>

                                <div className="bg-white" style={{ padding: "30px", marginBottom: "30px"}}>
                                    <h4 className="text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Reviews</h4>
                                    <div className="media mb-4">
                                        <img src="img/user.jpg" alt="Image" className="img-fluid mr-3 mt-1" style={{ width: "45px" }} />
                                        <div className="media-body">
                                            <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                                            <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                                                accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum.
                                                Gubergren clita aliquyam consetetur sadipscing, at tempor amet ipsum diam tempor
                                                consetetur at sit.</p>
                                            <button className="btn btn-sm btn-outline-primary">Reply</button>
                                        </div>
                                    </div>
                                    <div className="media">
                                        <img src="img/user.jpg" alt="Image" className="img-fluid mr-3 mt-1" style={{ width: "45px" }} />
                                        <div className="media-body">
                                            <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                                            <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                                                accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum.
                                                Gubergren clita aliquyam consetetur sadipscing, at tempor amet ipsum diam tempor
                                                consetetur at sit.</p>
                                            <button className="btn btn-sm btn-outline-primary">Reply</button>
                                            <div className="media mt-4">
                                                <img src="img/user.jpg" alt="Image" className="img-fluid mr-3 mt-1" style={{ width: "45px" }} />
                                                <div className="media-body">
                                                    <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                                                    <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor
                                                        labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed
                                                        eirmod ipsum. Gubergren clita aliquyam consetetur sadipscing, at tempor amet
                                                        ipsum diam tempor consetetur at sit.</p>
                                                    <button className="btn btn-sm btn-outline-primary">Reply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="pb-3">
                                    <div className="d-flex mb-3">
                                        <a className="text-primary text-uppercase text-decoration-none" href="">Posts { params.id }</a>
                                        <span className="text-primary px-2">|</span>
                                        <a className="text-primary text-uppercase text-decoration-none" href="">Latest Service</a>
                                    </div>
                                    
                                    <div className="row">
                                        {
                                            service.map((sr, index) => (
                                                <div className="col-lg-4 col-md-6 mb-4">
                                                    <div className="package-item bg-white mb-2">
                                                        <Link to={`/service/provider/${sr.id}`}>
                                                            <img className="img-fluid" src={sr.url} alt="" style={{width: "100%", height: "80%", maxHeight: "350px", maxWidth: "350px"}} />
                                                        </Link>
                                                        <div className="p-4">
                                                            <div className="d-flex justify-content-between mb-3">
                                                                <small className="m-0"><i className="fa fa-map-marker-alt text-primary mr-2"></i>location</small>
                                                                <small className="m-0"><i className="fa fa-clock text-primary mr-2"></i>{ sr.fee_condition }</small>
                                                                <small className="m-0"><i className="fa fa-cogs text-primary mr-2"></i>{ sr.tag }</small>
                                                            </div>
                                                            <Link to={`/service/provider/${sr.id}`}>
                                                                <a className="h5 text-decoration-none" href="">{sr.subcat_name}</a>
                                                                <h6 className="m-0">{sr.sp_id}</h6>
                                                            </Link>
                                                            <div className="border-top mt-4 pt-4">
                                                                <div className="d-flex justify-content-between">
                                                                    <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5</h6>
                                                                    <h5 className="m-0"><i className="fa fa-pound-sign text-primary mr-2"></i>{ sr.fee_chargeable }</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-lg-5" style={{ marginTop: "90px" }}>
                    <div className="row pt-5">
                        <div className="col-lg-3 col-md-6 mb-5">
                            <a href="" className="navbar-brand">
                                <h1 className="text-primary"><span className="text-white">Pink</span>Index</h1>
                            </a>
                            <p>Sed ipsum clita tempor ipsum ipsum amet sit ipsum lorem amet labore rebum lorem ipsum dolor. No sed vero lorem dolor dolor</p>
                            <h6 className="text-white text-uppercase mt-4 mb-3" style={{ letterSpacing: "5px" }}>Follow Us</h6>
                            <div className="d-flex justify-content-start">
                                <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                                <a className="btn btn-outline-primary btn-square" href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-5">
                            <h5 className="text-white text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Popular Categories</h5>
                            <div className="d-flex flex-column justify-content-start">
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>About</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Destination</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Services</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Packages</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Guides</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Testimonial</a>
                                <a className="text-white-50" href="#"><i className="fa fa-angle-right mr-2"></i>Blog</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-5">
                            <h5 className="text-white text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Links</h5>
                            <div className="d-flex flex-column justify-content-start">
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>About</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Destination</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Services</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Packages</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Guides</a>
                                <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Testimonial</a>
                                <a className="text-white-50" href="#"><i className="fa fa-angle-right mr-2"></i>Blog</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-5">
                            <h5 className="text-white text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Contact Us</h5>
                            <p><i className="fa fa-map-marker-alt mr-2"></i>123 Street, New York, USA</p>
                            <p><i className="fa fa-phone-alt mr-2"></i>+012 345 67890</p>
                            <p><i className="fa fa-envelope mr-2"></i>info@thepinkindex.com</p>
                            <h6 className="text-white text-uppercase mt-4 mb-3" style={{ letterSpacing: "5px" }}>Newsletter</h6>
                            <div className="w-100">
                                <div className="input-group">
                                    <input type="text" className="form-control border-light" style={{ padding: "25px" }} placeholder="Your Email" />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary px-3">Sign Up</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5">
                    <div className="row">
                        <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
                            <p className="m-0 text-white-50">Copyright &copy; <a href="#">thepinkindex.com</a>. All Rights Reserved.
                            </p>
                        </div>
                        <div className="col-lg-6 text-center text-md-right">
                            <p className="m-0 text-white-50">Designed by <a href="https://dcc2.com">Decode-Camp Consultant</a>
                            </p>
                        </div>
                    </div>
                </div>
                <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="fa fa-angle-double-up"></i></a>
            </body>
        </>
    )
}