import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './style.css';
import './owl.carousel.min.css';
import './tempusdominus-bootstrap-4.min.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Landing from "views/Landing";
import Home from "views/Home";
import HomeTest from "views/HomeTest";
import ProviderDashboard from "views/ProviderDashboard";
import UserDashboard from "views/UserDashboard";
import AdminDashboard from "views/AdminDashboard";
import Login from "views/Login";
import ForgetPassword from "views/ForgetPassword";
import Profile from "views/Profile";
import Register from "views/Register";
import SignUp from "views/SignUp";
import ClientRegistration from "views/ClientRegistration";
import ProviderRegistration from "views/ProviderRegistration";
import User from "views/User";
import ProviderPage from 'views/ProviderPage';
import Category from "views/Category";
import CategoryHair from "components/category/CategoryHair";
import CategoryFace from "components/category/CategoryFace";
import CategoryBody from "components/category/CategoryBody";
import UploadImage from "components/provider/UploadImage";
import Setup from "components/dashboard/Setup";
import SetupTable from "components/dashboard/SetupTable";
import axios from 'axios';
import StatusPostTable from "components/dashboard/StatusPostTable";
import StatusPost from "components/dashboard/StatusPost";
import ServiceTable from "components/dashboard/ServiceTable";
import PaymentTable from "components/dashboard/PaymentTable";
import PaymentPage from "components/dashboard/PaymentPage";
import PaymentComplete from "components/dashboard/PaymentComplete";
import WalletTable from "components/dashboard/WalletTable";
import ResetPassword from "views/ResetPassword";
import AddOn from "components/dashboard/AddOn";
import Policy from "components/dashboard/Policy";
import StarRating from "components/userDashboard/StarRating";
import Appointment from "components/dashboard/Appointment";
import AccountInfo from "components/dashboard/AccountInfo";
import ProviderPayment from "views/ProviderPayment";
import PaymentConfirmation from "views/PaymentConfimation";
import SinglePage from "views/SinglePage";
import ProviderNewDashboard from "views/ProviderNewDashboard";
axios.defaults.withCredentials = true

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/home' component={HomeTest} />
      <Route path='/land' component={Landing} />
      <Route path='/landing' component={Home} />
      <Route path='/provider/dashboard' component={ProviderDashboard} />
      <Route path='/user/dashboard' component={UserDashboard} />
      <Route path='/admin/dashboard' component={AdminDashboard} />
      <Route path='/login' component={Login} />
      <Route path='/forget/password' component={ForgetPassword} />
      <Route path='/reset/password/:id' component={ResetPassword} />
      <Route path='/profile' component={Profile} />
      <Route path='/register' component={SignUp} />
      <Route path='/user/register' component={ClientRegistration} />
      <Route path='/provider/register' component={ProviderRegistration} />
      <Route path='/service/provider/:id' component={ProviderPage} />
      <Route path='/service/category' component={Category} />
      <Route path='/service/categories/hair' component={CategoryHair} />
      <Route path='/service/categories/face' component={CategoryFace} />
      <Route path='/service/categories/body' component={CategoryBody} />
      <Route path='/provider/upload/image' component={UploadImage} />
      <Route path='/provider/service/setup' component={Setup} />
      <Route path='/provider/service/index' component={SetupTable} />
      <Route path='/provider/upload/status/index' component={StatusPostTable} />
      <Route path='/provider/upload/status' component={StatusPost} />
      <Route path='/booked/service' component={ServiceTable} />
      <Route path='/service/payment' component={PaymentTable} />
      <Route path='/payment/service/page/:pid' component={ProviderPayment} />
      <Route path='/payment/service/completion/:id/:ref' component={PaymentConfirmation} />
      <Route path="/provider/wallet/index" component={WalletTable} />
      <Route path="/provider/addons/create" component={AddOn} />
      <Route path="/provider/policy/upload" component={Policy} />
      <Route path="/client/star/rating" component={StarRating} />
      <Route path="/provider/create/appointment" component={Appointment} />
      <Route path="/provider/account/info" component={AccountInfo} />
      <Route path="/provider/explore/details/:id" component={SinglePage} />
      <Route path="/provider/new/dashboard" component={ProviderNewDashboard} />
      <Redirect from='/' to='/landing' />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
