import React from "react";


export default function ProviderNewDashboard() {

    return(
        <>
            <body>
                <div className="header">
                    <div className="header-left">
                        <div className="menu-icon dw dw-menu"></div>
                        <div className="search-toggle-icon dw dw-search2" data-toggle="header_search"></div>
                        <div className="header-search">
                            <form>
                                <div className="form-group mb-0">
                                    <i className="dw dw-search2 search-icon"></i>
                                    <input type="text" className="form-control search-input" placeholder="Search Here" />
                                    <div className="dropdown">
                                        <a className="dropdown-toggle no-arrow" href="#" role="button" data-toggle="dropdown">
                                            <i className="ion-arrow-down-c"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-md-2 col-form-label">From</label>
                                                <div className="col-sm-12 col-md-10">
                                                    <input className="form-control form-control-sm form-control-line" type="text" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-md-2 col-form-label">To</label>
                                                <div className="col-sm-12 col-md-10">
                                                    <input className="form-control form-control-sm form-control-line" type="text" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-md-2 col-form-label">Subject</label>
                                                <div className="col-sm-12 col-md-10">
                                                    <input className="form-control form-control-sm form-control-line" type="text" />
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <button className="btn btn-primary">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="dashboard-setting user-notification">
                            <div className="dropdown">
                                <a className="dropdown-toggle no-arrow" href="javascript:;" data-toggle="right-sidebar">
                                    <i className="fa fa-cog"></i>
                                </a>
                            </div>
                        </div>
                        <div className="user-notification">
                            <div className="dropdown">
                                <a className="dropdown-toggle no-arrow" href="#" role="button" data-toggle="dropdown">
                                    <i className="fa fa-bell"></i>
                                    <span className="badge notification-active"></span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="notification-list mx-h-350 customscroll">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <img src="vendors/images/img.jpg" alt="" />
                                                    <h3>John Doe</h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed...</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="vendors/images/photo1.jpg" alt="" />
                                                    <h3>Lea R. Frith</h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed...</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="vendors/images/photo1.jpg" alt="" />
                                                    <h3>Erik L. Richards</h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed...</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="vendors/images/photo1.jpg" alt="" />
                                                    <h3>John Doe</h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed...</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="vendors/images/photo1.jpg" alt="" />
                                                    <h3>Renee I. Hansen</h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed...</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <img src="vendors/images/img.jpg" alt="" />
                                                    <h3>Vicki M. Coleman</h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed...</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-info-dropdown">
                            <div className="dropdown">
                                <a className="dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                                    <span className="user-icon">
                                        <img src="vendors/images/photo1.jpg" alt="" />
                                    </span>
                                    <span className="user-name">Ross C. Lopez</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                    <a className="dropdown-item" href="profile.html"><i className="dw dw-user1"></i> Profile</a>
                                    <a className="dropdown-item" href="profile.html"><i className="dw dw-settings2"></i> Setting</a>
                                    <a className="dropdown-item" href="faq.html"><i className="dw dw-help"></i> Help</a>
                                    <a className="dropdown-item" href="login.html"><i className="dw dw-logout"></i> Log Out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="right-sidebar">
                    <div className="sidebar-title">
                        <h3 className="weight-600 font-16 text-blue">
                            Layout Settings
                            <span className="btn-block font-weight-400 font-12">User Interface Settings</span>
                        </h3>
                        <div className="close-sidebar" data-toggle="right-sidebar-close">
                            <i className="fa fa-banks"></i>
                        </div>
                    </div>
                    <div className="right-sidebar-body customscroll">
                        <div className="right-sidebar-body-content">
                            <h4 className="weight-600 font-18 pb-10">Header Background</h4>
                            <div className="sidebar-btn-group pb-30 mb-10">
                                <a href="javascript:void(0);" className="btn btn-outline-primary header-white active">White</a>
                                <a href="javascript:void(0);" className="btn btn-outline-primary header-dark">Dark</a>
                            </div>

                            <h4 className="weight-600 font-18 pb-10">Sidebar Background</h4>
                            <div className="sidebar-btn-group pb-30 mb-10">
                                <a href="javascript:void(0);" className="btn btn-outline-primary sidebar-light ">White</a>
                                <a href="javascript:void(0);" className="btn btn-outline-primary sidebar-dark active">Dark</a>
                            </div>

                            <h4 className="weight-600 font-18 pb-10">Menu Dropdown Icon</h4>
                            <div className="sidebar-radio-group pb-10 mb-10">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="sidebaricon-1" name="menu-dropdown-icon" className="custom-control-input" value="icon-style-1" checked="" />
                                    <label className="custom-control-label" for="sidebaricon-1"><i className="fa fa-angle-down"></i></label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="sidebaricon-2" name="menu-dropdown-icon" className="custom-control-input" value="icon-style-2" />
                                    <label className="custom-control-label" for="sidebaricon-2"><i className="ion-plus-round"></i></label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="sidebaricon-3" name="menu-dropdown-icon" className="custom-control-input" value="icon-style-3" />
                                    <label className="custom-control-label" for="sidebaricon-3"><i className="fa fa-angle-double-right"></i></label>
                                </div>
                            </div>

                            <h4 className="weight-600 font-18 pb-10">Menu List Icon</h4>
                            <div className="sidebar-radio-group pb-30 mb-10">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="sidebariconlist-1" name="menu-list-icon" className="custom-control-input" value="icon-list-style-1" checked="" />
                                    <label className="custom-control-label" for="sidebariconlist-1"><i className="ion-minus-round"></i></label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="sidebariconlist-2" name="menu-list-icon" className="custom-control-input" value="icon-list-style-2" />
                                    <label className="custom-control-label" for="sidebariconlist-2"><i className="fa fa-circle-o" aria-hidden="true"></i></label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="sidebariconlist-3" name="menu-list-icon" className="custom-control-input" value="icon-list-style-3" />
                                    <label className="custom-control-label" for="sidebariconlist-3"><i className="dw dw-check"></i></label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="sidebariconlist-4" name="menu-list-icon" className="custom-control-input" value="icon-list-style-4" checked="" /> 
                                    <label className="custom-control-label" for="sidebariconlist-4"><i className="icon-copy dw dw-next-2"></i></label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="sidebariconlist-5" name="menu-list-icon" className="custom-control-input" value="icon-list-style-5" />
                                    <label className="custom-control-label" for="sidebariconlist-5"><i className="dw dw-fast-forward-1"></i></label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="sidebariconlist-6" name="menu-list-icon" className="custom-control-input" value="icon-list-style-6" />
                                    <label className="custom-control-label" for="sidebariconlist-6"><i className="dw dw-next"></i></label>
                                </div>
                            </div>

                            <div className="reset-options pt-30 text-center">
                                <button className="btn btn-danger" id="reset-settings">Reset Settings</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="left-side-bar">
                    <div className="brand-logo">
                        <a href="index.html">
                            <img src="vendors/images/deskapp-logo.svg" alt="" className="dark-logo" />
                            <img src="vendors/images/deskapp-logo-white.svg" alt="" className="light-logo" />
                            The Pink Index
                        </a>
                        <div className="close-sidebar" data-toggle="left-sidebar-close">
                            <i className="ion-close-round"></i>
                        </div>
                    </div>
                    <div className="menu-block customscroll">
                        <div className="sidebar-menu">
                            <ul id="accordion-menu">
                                <li>
                                    <a href="#" className="dropdown-toggle no-arrow">
                                        <span className="fa fa-home"></span><span className="mtext"> Home</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="dropdown-toggle no-arrow">
                                        <span className="fa fa-vans"></span><span className="mtext"> Register Service</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="dropdown-toggle no-arrow">
                                        <span className="fa fa-refresh"></span><span className="mtext"> Setup Service</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="dropdown-toggle no-arrow">
                                        <span className="fa fa-upload"></span><span className="mtext"> Post Service</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="dropdown-toggle no-arrow">
                                        <span className="fa fa-edit"></span><span className="mtext"> Setup Addon's </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="dropdown-toggle no-arrow">
                                        <span className="fa fa-upload"></span><span className="mtext"> Upload Policy</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="dropdown-toggle no-arrow">
                                        <span className="fa fa-view"></span><span className="mtext"> View Schedule Details</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="dropdown-toggle no-arrow">
                                        <span className="fa fa-edit"></span><span className="mtext"> View Activities</span>
                                    </a>
                                </li>
                                <li className="dropdown">
                                    <a href="#" className="dropdown-toggle">
                                        <span className="fa fa-logout"></span><span className="mtext">Sign Out</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mobile-menu-overlay"></div>

                <div className="main-container">
                    <div className="pd-ltr-20">
                        <div className="card-box pd-20 height-100-p mb-30">
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <img src="vendors/images/banner-img.png" alt="" />
                                </div>
                                <div className="col-md-8">
                                    <h4 className="font-20 weight-500 mb-10 text-capitalize">
                                        Welcome back <div className="weight-600 font-30 text-blue">Johnny Brown!</div>
                                    </h4>
                                    <p className="font-18 max-width-600">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde hic non repellendus debitis iure, doloremque assumenda. Autem modi, corrupti, nobis ea iure fugiat, veniam non quaerat mollitia animi error corporis.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 mb-30">
                                <div className="card-box height-100-p widget-style1">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="progress-data">
                                            <div id="chart"></div>
                                        </div>
                                        <div className="widget-data">
                                            <div className="h4 mb-0">2020</div>
                                            <div className="weight-600 font-14">Contact</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-30">
                                <div className="card-box height-100-p widget-style1">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="progress-data">
                                            <div id="chart2"></div>
                                        </div>
                                        <div className="widget-data">
                                            <div className="h4 mb-0">400</div>
                                            <div className="weight-600 font-14">Deals</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-30">
                                <div className="card-box height-100-p widget-style1">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="progress-data">
                                            <div id="chart3"></div>
                                        </div>
                                        <div className="widget-data">
                                            <div className="h4 mb-0">350</div>
                                            <div className="weight-600 font-14">Campaign</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 mb-30">
                                <div className="card-box height-100-p widget-style1">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="progress-data">
                                            <div id="chart4"></div>
                                        </div>
                                        <div className="widget-data">
                                            <div className="h4 mb-0">$6060</div>
                                            <div className="weight-600 font-14">Worth</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 mb-30">
                                <div className="card-box height-100-p pd-20">
                                    <h2 className="h4 mb-20">Activity</h2>
                                    <div id="chart5"></div>
                                </div>
                            </div>
                            <div className="col-xl-4 mb-30">
                                <div className="card-box height-100-p pd-20">
                                    <h2 className="h4 mb-20">Lead Target</h2>
                                    <div id="chart6"></div>
                                </div>
                            </div>
                        </div>
                        <div className="card-box mb-30">
                            <h2 className="h4 pd-20">Best Selling Products</h2>
                            <table className="data-table table nowrap">
                                <thead>
                                    <tr>
                                        <th className="table-plus datatable-nosort">Product</th>
                                        <th>Name</th>
                                        <th>Color</th>
                                        <th>Size</th>
                                        <th>Price</th>
                                        <th>Oty</th>
                                        <th className="datatable-nosort">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="table-plus">
                                            <img src="vendors/images/product-1.jpg" width="70" height="70" alt="" />
                                        </td>
                                        <td>
                                            <h5 className="font-16">Shirt</h5>
                                            by John Doe
                                        </td>
                                        <td>Black</td>
                                        <td>M</td>
                                        <td>$1000</td>
                                        <td>1</td>
                                        <td>
                                            <div className="dropdown">
                                                <a className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                                                    <i className="dw dw-more"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                                    <a className="dropdown-item" href="#"><i className="dw dw-eye"></i> View</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-edit2"></i> Edit</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-delete-3"></i> Delete</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-plus">
                                            <img src="vendors/images/product-2.jpg" width="70" height="70" alt="" />
                                        </td>
                                        <td>
                                            <h5 className="font-16">Boots</h5>
                                            by Lea R. Frith
                                        </td>
                                        <td>brown</td>
                                        <td>9UK</td>
                                        <td>$900</td>
                                        <td>1</td>
                                        <td>
                                            <div className="dropdown">
                                                <a className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                                                    <i className="dw dw-more"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                                    <a className="dropdown-item" href="#"><i className="dw dw-eye"></i> View</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-edit2"></i> Edit</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-delete-3"></i> Delete</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-plus">
                                            <img src="vendors/images/product-3.jpg" width="70" height="70" alt="" />
                                        </td>
                                        <td>
                                            <h5 className="font-16">Hat</h5>
                                            by Erik L. Richards
                                        </td>
                                        <td>Orange</td>
                                        <td>M</td>
                                        <td>$100</td>
                                        <td>4</td>
                                        <td>
                                            <div className="dropdown">
                                                <a className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                                                    <i className="dw dw-more"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                                    <a className="dropdown-item" href="#"><i className="dw dw-eye"></i> View</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-edit2"></i> Edit</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-delete-3"></i> Delete</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-plus">
                                            <img src="vendors/images/product-4.jpg" width="70" height="70" alt="" />
                                        </td>
                                        <td>
                                            <h5 className="font-16">Long Dress</h5>
                                            by Renee I. Hansen
                                        </td>
                                        <td>Gray</td>
                                        <td>L</td>
                                        <td>$1000</td>
                                        <td>1</td>
                                        <td>
                                            <div className="dropdown">
                                                <a className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                                                    <i className="dw dw-more"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                                    <a className="dropdown-item" href="#"><i className="dw dw-eye"></i> View</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-edit2"></i> Edit</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-delete-3"></i> Delete</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-plus">
                                            <img src="vendors/images/product-5.jpg" width="70" height="70" alt="" />
                                        </td>
                                        <td>
                                            <h5 className="font-16">Blazer</h5>
                                            by Vicki M. Coleman
                                        </td>
                                        <td>Blue</td>
                                        <td>M</td>
                                        <td>$1000</td>
                                        <td>1</td>
                                        <td>
                                            <div className="dropdown">
                                                <a className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                                                    <i className="dw dw-more"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                                    <a className="dropdown-item" href="#"><i className="dw dw-eye"></i> View</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-edit2"></i> Edit</a>
                                                    <a className="dropdown-item" href="#"><i className="dw dw-delete-3"></i> Delete</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="footer-wrap pd-20 mb-20 card-box">
                            DeskApp - Bootstrap 4 Admin Template By <a href="https://github.com/dropways" target="_blank">Ankit Hingarajiya</a>
                        </div>
                    </div>
                </div>
            </body>
        </>
    )
}