import React from "react";

export default function Footer() {
  return (
    <footer className="relative pt-24 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "}The Pink Index by{" "}
              <a
                href="#decode"
                className="text-gray-600 hover:text-gray-900"
              >
                Decode-Camp Consulting
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
