import React, { useState, useEffect } from "react";
import Navbar from "components/dashboard/Navbar.js";
import Sidebar from "components/dashboard/Sidebar.js";
import { Link } from "react-router-dom";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useHistory } from "react-router";

export default function ProviderDashboard() {
  const [name, setName] = useState('')
  const [token, setToken] = useState('')
  const [expire, setExpire] = useState('')
  const [totalservice, setTotalService ] = useState([])
  const [totalwallet, setTotalWallet ] = useState([])
  const history = useHistory()

  useEffect(() => {
    refreshToken();
    getTotalService();
    getTotalWallet();
  }, []);

  async function getTotalService () {
    try{
      let url = 'https://app.thepinkindex.com/service/total';
      const data = await axiosJWT.get(url, { headers: { Authorization: `Bearer ${token}` }});
      console.log(data.data);
      setTotalService(data.data);
    }catch(err){
      console.log(err)
    }
  }

  async function getTotalWallet () {
    try{
      let url = 'https://app.thepinkindex.com/payment/transaction/wallet';
      const data = await axiosJWT.get(url, { headers: { Authorization: `Bearer ${token}` }});
      console.log(data.data);
      setTotalWallet(data.data);
    }catch(err){
      console.log(err)
    }
  }

  const refreshToken = async () => {
    try {
        const response = await axios.get('https://app.thepinkindex.com/token');
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.name);
        setExpire(decoded.expire);
    } catch (error) {
        if (error.response) {
          history.push("/");
        }
    }
  }

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
          const response = await axios.get('https://app.thepinkindex.com/token');
          config.headers.Authorization = `Bearer ${response.data.accessToken}`;
          setToken(response.data.accessToken);
          const decoded = jwt_decode(response.data.accessToken);
          setName(decoded.name);
          setExpire(decoded.expire);
      }
      return config;
  }, (error) => {
      return Promise.reject(error);
  });
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-500">
        <Navbar />
        {/* Header */}
        <div className="relative md:pt-32 pb-32 pt-6">
          <div className="px-4 md:px-10 mx-auto w-full">
            <div>
              {/* Card stats */}
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <Link to='/booked/service'>
                        <div className="flex flex-wrap">
                          <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                            <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                              Schedule Service
                            </h5>
                            <span className="font-semibold text-xl text-blueGray-700">
                              { 
                                totalservice.map((tot) => (
                                  tot.total
                                )) 
                              }
                            </span>
                          </div>
                          <div className="relative w-auto pl-4 flex-initial">
                            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500">
                              <i className="far fa-chart-bar"></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>               
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <Link>
                        <div className="flex flex-wrap">
                          <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                            <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                              Pending Service
                            </h5>
                            <span className="font-semibold text-xl text-blueGray-700">
                              2
                            </span>
                          </div>
                          <div className="relative w-auto pl-4 flex-initial">
                            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-blue-500">
                              <i className="fas fa-percent"></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <Link to='/provider/wallet/index'>
                        <div className="flex flex-wrap">
                          <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                            <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                              Completed Service
                            </h5>
                            <span className="font-semibold text-xl text-blueGray-700">
                              { 
                                totalwallet.map((wlet) => (
                                  parseFloat(wlet.total).toLocaleString()
                                )) 
                              }
                            </span>
                          </div>
                          <div className="relative w-auto pl-4 flex-initial">
                            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-pink-500">
                              <i className="fas fa-money-check-alt"></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                    <div className="flex-auto p-4">
                      <Link to='/provider/wallet/index'>
                        <div className="flex flex-wrap">
                          <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                            <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                              Total Service
                            </h5>
                            <span className="font-semibold text-xl text-blueGray-700">
                              { 
                                totalwallet.map((wlet) => (
                                  parseFloat(wlet.total).toLocaleString()
                                )) 
                              }
                            </span>
                          </div>
                          <div className="relative w-auto pl-4 flex-initial">
                            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-pink-500">
                              <i className="fas fa-edit"></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
        <section className="relative py-20">
          <div className="container mx-auto px-4">
            <h6 className="font-bold text-xl text-blueGray-900 mt-8 px-4 uppercase">Service Posted</h6>
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <article className="overflow-hidden rounded-lg shadow-lg">
                  <a href="#">
                    <img alt="Placeholder" className="block h-auto w-full" src="https://picsum.photos/400/300/?random" />
                  </a>

                  <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                    <h1 className="text-lg">
                        <a className="no-underline hover:underline text-black" href="#">
                            Service Provider Business  Name  
                        </a>
                    </h1>
                  </header>

                  <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                    <a className="flex items-center no-underline hover:underline text-black" href="#">
                        <img alt="Placeholder" className="block rounded-full" src="https://picsum.photos/32/32/?random" />
                        <p className="ml-2 text-sm">
                            Service Provider Name 
                        </p>
                    </a>
                    <a className="no-underline text-grey-darker hover:text-red-dark" href="#">
                        <span className="hidden">Like</span>
                        <i className="fa fa-heart"></i>
                    </a>
                  </footer>
                </article>
              </div>

              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <article className="overflow-hidden rounded-lg shadow-lg">
                  <a href="#">
                    <img alt="Placeholder" className="block h-auto w-full" src="https://picsum.photos/400/300/?random" />
                  </a>

                  <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                    <h1 className="text-lg">
                        <a className="no-underline hover:underline text-black" href="#">
                            Service Provider Business  Name  
                        </a>
                    </h1>
                  </header>

                  <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                    <a className="flex items-center no-underline hover:underline text-black" href="#">
                        <img alt="Placeholder" className="block rounded-full" src="https://picsum.photos/32/32/?random" />
                        <p className="ml-2 text-sm">
                            Service Provider Name 
                        </p>
                    </a>
                    <a className="no-underline text-grey-darker hover:text-red-dark" href="#">
                        <span className="hidden">Like</span>
                        <i className="fa fa-heart"></i>
                    </a>
                  </footer>
                </article>
              </div>

              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <article className="overflow-hidden rounded-lg shadow-lg">
                  <a href="#">
                    <img alt="Placeholder" className="block h-auto w-full" src="https://picsum.photos/400/300/?random" />
                  </a>

                  <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                    <h1 className="text-lg">
                        <a className="no-underline hover:underline text-black" href="#">
                            Service Provider Business  Name  
                        </a>
                    </h1>
                  </header>

                  <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                    <a className="flex items-center no-underline hover:underline text-black" href="#">
                        <img alt="Placeholder" className="block rounded-full" src="https://picsum.photos/32/32/?random" />
                        <p className="ml-2 text-sm">
                            Service Provider Name 
                        </p>
                    </a>
                    <a className="no-underline text-grey-darker hover:text-red-dark" href="#">
                        <span className="hidden">Like</span>
                        <i className="fa fa-heart"></i>
                    </a>
                  </footer>
                </article>
              </div> 
            </div>
          </div>
        </section>
        </div>
      </div>
    </>
  );
}
