import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { setDefaults, fromAddress } from "react-geocode";
import storage from "../config/firebaseConfig.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default function ProviderRegistration() {
    const [fullname, setFullName] = useState('')
    const [business, setBusiness] = useState('')
    const [email, setEmail] = useState('')
    const [pno, setPno] = useState('')
    const [address, setAddress] = useState('')
    const [password, setPassword] = useState('')
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [ip, setIP] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [bio, setBio] = useState('')
    const [file, setFile] = useState('')
    const [fils, setFils] = useState('')
    const [urls, setUrls] = useState('')
    const [tagcheck, setTagCheck] = useState("Basic")
    const history = useHistory()

    useEffect(() => {
        fetchIp();
    }, []);

    const onOptionChange = e =>{
        setTagCheck(e.target.value)
    }

    function handleChange(e) {
        console.log(e.target.files);
        setFile(e.target.files[0]);
        setFils(URL.createObjectURL(e.target.files[0]));
    }

    setDefaults({
        key: "AIzaSyBiN2rHBXw9p-PTKr1jYxLkYFDulIbRwYk", // Your API key here.
        language: "en", // Default language for responses.
        region: "es", // Default region for responses.
    });

    const fetchIp = async () => {
        try {
          const response = await fetch("https://geolocation-db.com/json/");
          const data = await response.json();
          setLat(data.latitude);
          setLng(data.longitude);
          setIP(data.IPv4);
          setCity(data.city);
          setState(data.state);
          setCountry(data.country_name);
          console.log(data);
        } catch (error) {
          console.error(error);
        }
    };

    const handleUpload = () => {
        const storageRef = ref(storage, `/profile/pictures/${file}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
  
        uploadTask.on("state_changed",(snapshot) => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          
        },(err) => console.log(err),() => {
          getDownloadURL(uploadTask.snapshot.ref).then((res) => {
            setUrls(res);
            console.log(res);
          });
        });
    }
    
    const Auth = async (e) => {
        e.preventDefault()
        try {
            /*fromAddress(address)
            .then(({ results }) => {
                const { lats, lngs } = results[0].geometry.location;
                console.log(lats, lngs);
            }).catch(console.error);*/

            await axios.post('https://app.thepinkindex.com/register/provider', {
                fullname: fullname,
                business: business,
                email: email,
                urls: urls,
                bio: bio,
                pno: pno,
                address: address,
                lat: lat,
                lng: lng,
                ip: ip,
                city: city,
                state: state,
                country: country,
                password: password,
                subplan: tagcheck
            })
            history.push(`/payment/service/page/${email}`)
        } catch (error) {
          if (error.response) {
            toast.info('👿👿👿' + error.response.data.msg, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            })
          }
        }
    }
  return (
    <>
    <body>
    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    <div className="container-fluid bg-light pt-3 d-none d-lg-block">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
                    <div className="d-inline-flex align-items-center">
                        <p><i className="fa fa-envelope mr-2"></i>info@thepinkindex.com</p>
                    </div>
                </div>
                <div className="col-lg-6 text-center text-lg-right">
                    <div className="d-inline-flex align-items-center">
                        <a className="text-pink px-3" href="/login">
                            <i className="fa fa-lock"></i> Login
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div className="container-fluid position-relative nav-bar p-0">
        <div className="container-lg position-relative p-0 px-lg-3" style={{zIndex: "9" }}>
            <nav className="navbar navbar-expand-lg bg-light navbar-light shadow-lg py-3 py-lg-0 pl-3 pl-lg-5">
                <a href="/home" className="navbar-brand">
                    <h1 className="m-0 text-primary"><span className="text-dark">Pink</span>Index</h1>
                </a>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
                    <div className="navbar-nav ml-auto py-0">
                        <a href="/home" className="nav-item nav-link active">Home</a>
                        <a href="#" className="nav-item nav-link">About</a>
                        <a href="#" className="nav-item nav-link">Services</a>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Pages</a>
                            <div className="dropdown-menu border-0 rounded-0 m-0">
                                <a href="#" className="dropdown-item">Blog Grid</a>
                                <a href="#" className="dropdown-item">Blog Detail</a>
                                <a href="#" className="dropdown-item">Destination</a>
                                <a href="#" className="dropdown-item">Travel Guides</a>
                                <a href="#" className="dropdown-item">Testimonial</a>
                            </div>
                        </div>
                        <a href="#" className="nav-item nav-link">Contact</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div className="container-fluid py-5">
        <div className="container py-5">
            <div className="text-center mb-3 pb-3">
                <h6 className="text-primary text-uppercase" style={{letterSpacing: "5px"}}>Sign Up</h6>
                <h1>Provider Registration Form</h1>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="contact-form bg-white" style={{padding: "30px"}}>
                        <div id="success"></div>
                        <form name="sentMessage" id="contactForm" novalidate="novalidate" onSubmit={Auth}>
                            <div className="control-group">
                                <div className="col-span-6 ml-2 sm:col-span-4 md:mr-3">
                                    <div className="text-center">
                                        <label>Profile Picture</label>
                                        <div className="mt-2">
                                            <img src={fils} className="w-40 h-40 m-auto rounded-full shadow" />
                                        </div>
                                        
                                        <input type="file" accept="/image/*" onBlur={handleUpload} onChange={handleChange} className="mb-4 inline-flex items-center px-0 py-0 bg-white border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150 mt-2 ml-3" />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="control-group">
                                <input type="text" className="form-control p-4" id="fullname" placeholder="Fullname" value={fullname} onChange={(e) => setFullName(e.target.value)}
                                    required="required" data-validation-required-message="Please enter your fullname" />
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                                <input type="text" className="form-control p-4" id="businessname" placeholder="Business Name" value={business} onChange={(e) => setBusiness(e.target.value)}
                                    required="required" data-validation-required-message="Please enter your business name" />
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="form-row">
                                <div className="control-group col-sm-6">
                                    <input type="email" className="form-control p-4" id="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)}
                                        required="required" data-validation-required-message="Please enter your email" />
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div className="control-group col-sm-6">
                                    <input type="text" className="form-control p-4" id="number" placeholder="Your Number" value={pno} onChange={(e) => setPno(e.target.value)}
                                        required="required" data-validation-required-message="Please enter your number" />
                                    <p className="help-block text-danger"></p>
                                </div>
                            </div>
                            <div className="control-group">
                                <textarea className="form-control py-3 px-4" rows="5" id="bio" placeholder="Tell us about yourself and service" value={bio} onChange={(e) => setBio(e.target.value)}
                                    required="required"
                                    data-validation-required-message="Tell us about yourself and service"></textarea>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                                <textarea className="form-control py-3 px-4" rows="5" id="address" placeholder="Your Office Address" value={address} onChange={(e) => setAddress(e.target.value)}
                                    required="required"
                                    data-validation-required-message="Please enter your office address"></textarea>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                                <input type="password" className="form-control p-4" id="password" placeholder="Your Password" value={password} onChange={(e) => setPassword(e.target.value)}
                                    required="required" data-validation-required-message="Please enter a password" />
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group mt-4">
                                <label className="block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
                                    Select Subscription Plan
                                </label>
                                <input type="radio" value="Basic" checked={tagcheck === "Basic"} onChange={onOptionChange} className="p-4 mr-1 bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                                <span className="mr-2">Basic $100 Monthly</span>
                                <input type="radio" value="Premium" checked={tagcheck === "Premium"} onChange={onOptionChange} className="p-4 mr-1 bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                                <span className="mr-2">Premium $150 Monthly</span>
                                <input type="radio" value="Diamond" checked={tagcheck === "Diamond"} onChange={onOptionChange} className="p-4 mr-1 bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                                <span className="mr-2">Diamond $200 Monthly</span>
                            </div>
                            
                            <div className="text-center mt-4">
                                <input type="submit" className="btn btn-primary py-3 px-4" id="sendMessageButton" value="Save & Continue" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="container-fluid bg-dark text-white-50 py-5 px-sm-3 px-lg-5" style={{ marginTop: "90px" }}>
        <div className="row pt-5">
            <div className="col-lg-3 col-md-6 mb-5">
                <a href="" className="navbar-brand">
                    <h1 className="text-primary"><span className="text-white">Pink</span>Index</h1>
                </a>
                <p>Sed ipsum clita tempor ipsum ipsum amet sit ipsum lorem amet labore rebum lorem ipsum dolor. No sed vero lorem dolor dolor</p>
                <h6 className="text-white text-uppercase mt-4 mb-3" style={{ letterSpacing: "5px" }}>Follow Us</h6>
                <div className="d-flex justify-content-start">
                    <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-facebook-f"></i></a>
                    <a className="btn btn-outline-primary btn-square mr-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                    <a className="btn btn-outline-primary btn-square" href="#"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Popular Categories</h5>
                <div className="d-flex flex-column justify-content-start">
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>About</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Destination</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Services</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Packages</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Guides</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Testimonial</a>
                    <a className="text-white-50" href="#"><i className="fa fa-angle-right mr-2"></i>Blog</a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Links</h5>
                <div className="d-flex flex-column justify-content-start">
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>About</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Destination</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Services</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Packages</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Guides</a>
                    <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Testimonial</a>
                    <a className="text-white-50" href="#"><i className="fa fa-angle-right mr-2"></i>Blog</a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="text-white text-uppercase mb-4" style={{ letterSpacing: "5px" }}>Contact Us</h5>
                <p><i className="fa fa-map-marker-alt mr-2"></i>123 Street, New York, USA</p>
                <p><i className="fa fa-phone-alt mr-2"></i>+012 345 67890</p>
                <p><i className="fa fa-envelope mr-2"></i>info@thepinkindex.com</p>
                <h6 className="text-white text-uppercase mt-4 mb-3" style={{ letterSpacing: "5px" }}>Newsletter</h6>
                <div className="w-100">
                    <div className="input-group">
                        <input type="text" className="form-control border-light" style={{ padding: "25px" }} placeholder="Your Email" />
                        <div className="input-group-append">
                            <button className="btn btn-primary px-3">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5">
        <div className="row">
            <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
                <p className="m-0 text-white-50">Copyright &copy; <a href="#">thepinkindex.com</a>. All Rights Reserved.
                </p>
            </div>
            <div className="col-lg-6 text-center text-md-right">
                <p className="m-0 text-white-50">Designed by <a href="https://dcc2.com">Decode-Camp Consultant</a>
                </p>
            </div>
        </div>
    </div>
    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="fa fa-angle-double-up"></i></a>
    </body>
    </>
  );
}
